<template>
  <div>
    <!-- Table Container Card -->
    <b-card no-body class="mb-0">
      <div class="m-2">
        <!-- Table Top -->
        <b-row>
          <!-- Per Page -->
          <b-col
          v-if="$can('delete', 'upcoming_course')"
          cols="12"  class="d-flex align-items-end justify-content-end mb-1">
          <b-button variant="primary" class="mr-1" @click="DeleteRows">
            Delete Selected Course
          </b-button>
        </b-col>
        </b-row>
        <b-row>
          <!-- Per Page -->
           <!-- Search -->
           
          <b-col
            cols="3"
            md="3"
            class="
              d-flex
              align-items-center
              justify-content-start
              mb-1 mb-md-0
            ">
            <label>Show</label>
            <v-select
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              v-model="perPage"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50" />
            <label>entries</label>
          </b-col>
          <b-col cols="3">
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                v-model="searchQuery"
                class="d-inline-block mr-1"
                placeholder="Search..." />
           
            </div>
          </b-col>
          <b-col
            cols="6"
            class="mt-50 d-flex align-items-end justify-content-end">
            <b-button variant="primary" class="mr-1" @click="getData">
              Save Changes
            </b-button>
          
          </b-col>

          <!-- Search -->
        </b-row>
      </div>

      <b-table
        ref="refCourseListTable"
        class="position-relative"
        responsive
        :fields="tableColumns"
        primary-key="id"
        :items="categoriesList"
        select-mode="multi"
        selectable
        @row-selected="onRowSelected"
        :sort-by.sync="sort"
        show-empty
    
        empty-text="Loading">
        <!-- Column: Status -->
        <template #cell(visible)="data">
          <b-form-group class="mb-0">
            <b-form-radio-group
              v-model="data.item.visible"
              class="demo-inline-spacing">
              <b-form-radio :value="yes" v-model="data.item.visible">
                yes
              </b-form-radio>
              <b-form-radio :value="no" v-model="data.item.visible">
                No
              </b-form-radio>
            </b-form-radio-group>
          </b-form-group>
        </template>

        <!-- Column: Actions -->

        <!-- Column: Actions -->

        <template #cell(actions)="data">
          <a
          
          target="_blank"
    
            :href="`https://api.lmitac.com/${data.item.link_id}`"
          >
          <feather-icon icon="LinkIcon" />
</a>
          <a
          v-if="$can('delete', 'upcoming_course')"
            style="margin: 0px 12px"
            role="button"
            @click="DeleteItem(data.item.id)">
            <feather-icon icon="TrashIcon" />
          </a>
        </template>
      </b-table>
      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="
              d-flex
              align-items-center
              justify-content-center justify-content-sm-start
            ">
            <span class="text-muted">
              Showing {{ dataMeta.from }} to {{ dataMeta.to }} of
              {{ dataMeta.of }} entries
            </span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="
              d-flex
              align-items-center
              justify-content-center justify-content-sm-end
            ">
            <b-pagination
              v-model="currentPage"
              :total-rows="totalCategories"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item">
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BDropdownItemButton,
  BFormRadio,
  BFormRadioGroup,
  BFormGroup,
} from "bootstrap-vue";
import vSelect from "vue-select";
import store from "@/store";
import { ref, watch, computed, reactive } from "@vue/composition-api";
import Vue from "vue";

// import useCousresList from './useCousresList'
// import userStoreModule from '../userStoreModule'

export default {
  components: {
    // useCousresList,
    BFormRadio,
    BFormRadioGroup,
    BFormGroup,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BDropdownItemButton,

    vSelect,
  },
  setup() {
    const categoriesList = ref([]);
    const yes = ref(1);
    const no = ref(0);
    const perPageOptions = [25, 50, 100, 200];
    const perPage = ref(10);
    const totalCategories = ref(0);
    const currentPage = ref(1);
    const refCourseListTable = ref("");
    const visiable = ref("");
    const selectedRow = ref([]);
    const onRowSelected = (items) => {
      selectedRow.value = items;
      console.log("selectedRow", selectedRow);
    };
    const DeleteRows = () => {
      console.log("se", selectedRow.value);
      let data = [];
      selectedRow.value.forEach((el) => {
        data.push(el.id);
      });
      Vue.swal({
        icon: "warning",
        title: "Are You Sure?",
        confirmButtonText: "YES",
        cancelButtonText: "Cancel",
        customClass: {
          confirmButton: "btn btn-primary",
        },
      }).then((result) => {
        if (result.value) {
          console.log("data",data);
          store
            .dispatch("homepage/DeleteSelected", data)
            .then((response) => {
              getDataUpComing();
              Vue.swal({
                title: "UpComing Courses Deleted ",
                icon: "success",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                buttonsStyling: false,
              });
            });
        }
      });
    };
    const getData = () => {
      const formData = new FormData();
      formData.append("data", JSON.stringify(categoriesList.value));
      console.log("data", categoriesList.value);
      store
        .dispatch("homepage/addAllUpComingCourse", formData)
        .then((response) => {
          Vue.swal({
            title: " Changes saved successfuly ",
            icon: "success",
            customClass: {
              confirmButton: "btn btn-primary",
            },
            buttonsStyling: false,
          });
        })
        .catch((error) => {
          Vue.swal({
            title: "",
            text: `${error.response.data.message}`,
            icon: "error",
            confirmButtonText: "Ok",
            customClass: {
              confirmButton: "btn btn-primary",
            },
            buttonsStyling: false,
          });
        });
    };

    const tableColumns = [
      { key: "id", label: "Id", sortable: true },
      { label: "Type", key: "type", sortable: true },
      {
        label: "Course Name",
        key: "course_name",

        sortable: true,
      },
      { label: "Schedule", key: "schedule", sortable: true },
      {
        label: "Slider Order",
        key: "in_slider_order",

        sortable: true,
      },
      {
        key: "visible",
        label: "visible",

        sortable: true,
      },
      {
        key: "city_name",
        label: "Venue",

        sortable: true,
      },

      { key: "actions" },
    ];
    const searchQuery = ref("");
    const sort=ref('')
    const SortArray=[]
    watch([currentPage, perPage, searchQuery,sort], () => {
      getDataUpComing();
    });
    const dataMeta = computed(() => {
      const localItemsCount = refCourseListTable.value
        ? refCourseListTable.value.localItems.length
        : 0;
      return {
        from:
          perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
        to: perPage.value * (currentPage.value - 1) + localItemsCount,
        of: totalCategories.value,
      };
    });
    const meta = reactive({});
    const getDataUpComing = () => {
      let data=null
   
   if( !SortArray.includes(sort.value) && sort.value!='' ){
   
     SortArray.push(sort.value)
     data=SortArray.toString().replace('[','')
     }
      store
        .dispatch("homepage/AllUpComingCourse", {
          per_page: perPage.value,
          "filter[search]": searchQuery.value,
          sort:data,
          page: currentPage.value,
        })
        .then((response) => {
          console.log("AllUpComingCourse", response.data);
          categoriesList.value = response.data.data;
          totalCategories.value = response.data.meta.total;
          console.log("totalCategories", totalCategories);
        });
    };
    getDataUpComing();
    const DeleteItem = (id) => {
      Vue.swal({
        icon: "warning",
        title: "Are You Sure?",
        confirmButtonText: "YES",
        cancelButtonText: "Cancel",
        customClass: {
          confirmButton: "btn btn-primary",
        },
      }).then((result) => {
        if (result.value) {
          store.dispatch("homepage/DeleteUPComing", id).then((response) => {
            getDataUpComing();
            Vue.swal({
              title: " Deleted ",
              icon: "success",
              customClass: {
                confirmButton: "btn btn-primary",
              },
              buttonsStyling: false,
            });
          });
        }
      });
    };

    return {
      tableColumns,
      searchQuery,
      yes,
      no,
      categoriesList,
      DeleteItem,
      meta,
      visiable,
      getDataUpComing,
      perPageOptions,
      totalCategories,
      getData,
      currentPage,
      perPage,
      dataMeta,
      refCourseListTable,
      onRowSelected,
      selectedRow,
      DeleteRows,
      SortArray,
      sort
    };
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
